<template>
  <BaseDialog
    @input="$emit('input', $event)"
    label="Отчеты"
    :value="value"
    is-cross-close
    :max-width="600"
  >
    <template #content>
      <BaseReport
        v-for="report in reportList"
        :key="report.actionName"
        :value="ids"
        :action-name="report.actionName"
        :report-label="report.reportLabel"
        :params-to-upload="config"
        command-type="reporting"
      />
    </template>
  </BaseDialog>
</template>

<script>
import BaseReport from '@/components/base/BaseReportPanel/BaseReport'
import objectReports from '@/components/base/BaseReportPanel/objectReports'
import passportReports from '@/components/base/BaseReportPanel/passportReports'
import BaseDialog from '@/components/base/BaseDialog'
import { typesFileReports } from '@/config/common'
import ConstructorFiles from '@/components/structures/constructorFiles'

export default {
  name: 'BaseReportPanel',
  components: { BaseDialog, BaseReport },
  props: {
    value: Boolean,
    selectedItems: Array,
    reportType: String,
    eavEntityId: Number
  },
  data () {
    return {
      config: new ConstructorFiles({ commandType: 'reporting' }).data
    }
  },
  watch: {
    value: {
      handler () {
        this.$emit('input', this.value)
      },
      immediate: true
    }
  },
  computed: {
    eav_entity_id () {
      if (this.eavEntityId) {
        return this.eavEntityId
      } else {
        return this.$route.params.entityId
      }
    },
    reportList () {
      let reportList = []
      this.config.params.config = []
      if (this.reportType === 'object') {
        reportList = objectReports.filter(report => report.entityId === Number(this.$route.params.entityId) || report.entityId === null)
        this.config.params.config.push(new ConstructorFiles().objectsConfig({
          ids: this.ids,
          type: typesFileReports.xls.object,
          eav_entity_id: this.eav_entity_id
        }))
      } else if (this.reportType === 'passport') {
        if (this.$route.name === 'passport') {
          reportList = passportReports.filter(report => report.actionName === typesFileReports.reestr)
          this.selectedItems?.map((item) => {
            if (this.config.params.config.length === 0) {
              this.config.params.config.push(new ConstructorFiles().baseConfig({
                id: [item.properties.id],
                type: typesFileReports.reestr
              }))
            } else {
              this.config.params.config[0].options.query.id.push(item.properties.id)
            }
          })
        } else {
          reportList = passportReports
        }
      } else if (this.reportType === 'nasajdeniya') {
        reportList = objectReports.filter(report => report.actionName === 'porubochniy-bilet')
      } else if (this.reportType === 'fellingTicket') {
        reportList = objectReports.filter(report => report.actionName === 'felling-ticket-list')
      }
      return reportList
    },
    ids () {
      if (this.reportType === 'nasajdeniya') {
        return this.selectedItems.map(item => item.nasajdeniya.properties.id)
      } else if (this.reportType === 'fellingTicket') {
        return this.selectedItems.map(item => item.id)
      } else {
        return this.selectedItems.map(item => item.properties.id)
      }
    }
  },
  methods: {
    handleClose () {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped>

</style>
